<template>
  <!-- Banner Section -->
  <section class="relative bg-cover bg-center" style="background-image: url('your-background-image.jpg');">
    <div class="absolute inset-0 bg-gradient-to-b from-white/80 to-white/50"></div>
    <div class="relative max-w-4xl mx-auto py-12 md:py-20 px-6 text-center">
      <h2 class="text-4xl md:text-5xl font-bold text-gray-800 drop-shadow-md">
        Are your investments growing as they should?
      </h2>
      <p class="mt-4 text-xl text-gray-600 drop-shadow-sm">
        Compare your portfolio performance to market averages.
      </p>
      <div class="mt-8">
        <a
          href="#calculator"
          @click.prevent="scrollToCalculator"
          class="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded transition duration-300 shadow-lg"
        >
          Try Our Calculator
        </a>
      </div>
    </div>
  </section>

  <!-- Calculator Form Section -->
  <div id="calculator" class="min-h-screen bg-gray-100 flex flex-col items-center font-sans relative">
    <div class="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl mt-8 mx-4">
      <form @submit.prevent="submitData" class="space-y-6">
        <!-- Basic Input Fields -->
        <div>
          <label class="block text-gray-700 font-semibold mb-1">Start Date</label>
          <input
            id="start-date-input"
            ref="startDateInput"
            type="date"
            v-model="startDate"
            class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <div v-if="errors.startDate" class="text-red-500 text-sm mt-1">{{ errors.startDate }}</div>
        </div>
        <div>
          <label class="block text-gray-700 font-semibold mb-1">Initial Investment ($)</label>
          <input
            type="text"
            inputmode="numeric"
            v-model="initialInvestment"
            @blur="formatField('initialInvestment')"
            @focus="unformatField('initialInvestment')"
            class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <div v-if="errors.initialInvestment" class="text-red-500 text-sm mt-1">{{ errors.initialInvestment }}</div>
        </div>
        <div>
          <label class="block text-gray-700 font-semibold mb-1">Country</label>
          <select
            v-model="selectedCountry"
            class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          >
            <option disabled value="">Select a country</option>
            <option value="US">US</option>
            <option value="Canada">Canada</option>
          </select>
          <div v-if="errors.selectedCountry" class="text-red-500 text-sm mt-1">{{ errors.selectedCountry }}</div>
        </div>

        <!-- Risk Tolerance Section with Modal Trigger -->
        <div>
          <label class="block text-gray-700 font-semibold mb-1">Risk Tolerance</label>
          <div class="flex items-center">
            <select
              v-model="selectedRiskTolerance"
              class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            >
              <option disabled value="">Select risk tolerance</option>
              <option value="Aggressive">Aggressive</option>
              <option value="Balanced">Balanced</option>
              <option value="Conservative">Conservative</option>
              <option value="Risk-Free">Risk-Free</option>
            </select>
            <!-- Button to open the modal -->
            <button
              type="button"
              class="ml-2 text-blue-500 text-lg hover:text-blue-700"
              @click="showRiskModal = true"
            >
              ℹ️
            </button>
          </div>
          <div v-if="errors.selectedRiskTolerance" class="text-red-500 text-sm mt-1">
            {{ errors.selectedRiskTolerance }}
          </div>
        </div>

        <!-- Optional Contributions Section Toggle -->
        <div class="mb-4">
          <label class="block text-gray-700 font-semibold mb-1">Additional Contributions/Withdrawals (Optional)</label>
          <div class="flex gap-4 flex-wrap">
            <button
              type="button"
              @click="selectedWorkflow = 'none'"
              :class="{'bg-blue-600 text-white': selectedWorkflow === 'none', 'bg-gray-200 text-gray-700': selectedWorkflow !== 'none'}"
              class="px-4 py-2 rounded-lg mb-2"
            >
              None
            </button>
            <button
              type="button"
              @click="selectedWorkflow = 'oneTime'"
              :class="{'bg-blue-600 text-white': selectedWorkflow === 'oneTime', 'bg-gray-200 text-gray-700': selectedWorkflow !== 'oneTime'}"
              class="px-4 py-2 rounded-lg mb-2"
            >
              One-Time Deposits/Withdrawals
            </button>
            <button
              type="button"
              @click="selectedWorkflow = 'recurring'"
              :class="{'bg-blue-600 text-white': selectedWorkflow === 'recurring', 'bg-gray-200 text-gray-700': selectedWorkflow !== 'recurring'}"
              class="px-4 py-2 rounded-lg mb-2"
            >
              Recurring Contributions
            </button>
          </div>
        </div>

        <!-- Workflow-Specific Inputs -->
        <div v-if="selectedWorkflow === 'oneTime'">
          <label class="block text-gray-700 font-semibold mb-1">
            Additional Deposits/Withdrawals (Optional)
          </label>
          <p class="text-gray-500 text-xs mb-2">
            {{ manualContributionHelper }}
          </p>
          <button
            type="button"
            @click="addTransaction"
            class="mt-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md shadow-md transition-all"
          >
            + Add Transaction
          </button>
          <div
            v-for="(tx, index) in transactions"
            :key="index"
            class="flex gap-3 items-center mt-2 flex-wrap"
          >
            <input
              type="date"
              v-model="tx.date"
              class="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <input
              type="text"
              inputmode="numeric"
              v-model="tx.amount"
              @blur="formatTransactionAmount(index)"
              @focus="unformatTransactionAmount(index)"
              class="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Amount"
            />
            <button
              type="button"
              @click="removeTransaction(index)"
              class="text-red-500 hover:text-red-700 font-bold text-xl"
            >
              ✖
            </button>
          </div>
        </div>
        <div v-else-if="selectedWorkflow === 'recurring'">
          <label class="block text-gray-700 font-semibold mb-1">Recurring Contributions (Optional)</label>
          <p class="text-gray-500 text-xs mb-2">
            (For example, if you regularly invest a fixed amount every month)
          </p>
          <div class="flex gap-4 items-center">
            <input
              type="text"
              inputmode="numeric"
              v-model="recurringInvestmentAmount"
              @blur="formatField('recurringInvestmentAmount')"
              @focus="unformatField('recurringInvestmentAmount')"
              placeholder="Amount"
              class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
          </div>
          <div v-if="errors.recurringInvestmentAmount" class="text-red-500 text-sm mt-1">
            {{ errors.recurringInvestmentAmount }}
          </div>
          <div class="flex space-x-2 mt-2 flex-wrap">
            <button
              type="button"
              @click="recurringFrequency = 'Monthly'"
              :class="{'bg-blue-600 text-white': recurringFrequency==='Monthly', 'bg-gray-200 text-gray-700': recurringFrequency !== 'Monthly'}"
              class="px-4 py-2 rounded-lg mb-2"
            >
              Monthly
            </button>
            <button
              type="button"
              @click="recurringFrequency = 'Annually'"
              :class="{'bg-blue-600 text-white': recurringFrequency==='Annually', 'bg-gray-200 text-gray-700': recurringFrequency !== 'Annually'}"
              class="px-4 py-2 rounded-lg mb-2"
            >
              Annually
            </button>
          </div>
          <div v-if="errors.recurringFrequency" class="text-red-500 text-sm mt-1">
            {{ errors.recurringFrequency }}
          </div>
        </div>
        <div>
          <label class="block text-gray-700 font-semibold mb-1">Final Portfolio Value ($)</label>
          <input
            type="text"
            inputmode="numeric"
            v-model="finalValue"
            @blur="formatField('finalValue')"
            @focus="unformatField('finalValue')"
            class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
          />
          <div v-if="errors.finalValue" class="text-red-500 text-sm mt-1">
            {{ errors.finalValue }}
          </div>
        </div>

        <!-- Submit Button with Loading Indicator -->
        <div>
          <button
            type="submit"
            class="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-3 rounded-lg shadow-md transition-all"
            :disabled="loading"
          >
            {{ loading ? "Calculating..." : "Calculate Performance 📈" }}
          </button>
        </div>
      </form>

      <!-- Results Section -->
      <div v-if="result" class="mt-8 bg-gray-50 p-6 rounded-lg text-center shadow">
        <h3 class="text-xl font-semibold text-gray-700">Results</h3>
        <p class="text-gray-600">
          <strong>Your Portfolio Value:</strong>
          ${{ result?.user_final_value?.toFixed(0) || "N/A" }}
        </p>
        <p class="text-gray-600">
          <strong>Benchmark Portfolio Value:</strong>
          ${{ result?.benchmark_final_value?.toFixed(0) || "N/A" }}
        </p>
        <p v-if="result?.difference < 0" class="text-red-600 font-semibold">
          You have <strong>${{ Math.abs(result.difference).toFixed(0) }}</strong> less than expected.
        </p>
        <p v-else class="text-green-600 font-semibold">
          You have <strong>${{ result.difference.toFixed(0) }}</strong> more than expected!
        </p>
        <ChartComponent
          v-if="chartData"
          :key="chartKey"
          :chartMode="'portfolio'"
          :userData="chartData.user"
          :benchmarkData="chartData.benchmark"
        />
        <!-- Call to Action -->
        <div class="mt-6">
          <a
            href="/benchmarks"
            class="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded transition duration-300 shadow-lg"
          >
            Explore Benchmark Portfolios
          </a>
        </div>
      </div>
    </div>
  </div>

  <!-- Risk Tolerance Modal -->
  <transition name="fade">
    <div 
      v-if="showRiskModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      @click.self="showRiskModal = false"
    >
      <div class="bg-white rounded-lg p-6 max-w-sm w-full relative">
        <!-- Close Button -->
        <button
          class="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl leading-none"
          @click="showRiskModal = false"
        >
          &times;
        </button>
        <h2 class="text-xl font-bold mb-4">Risk Tolerance Info</h2>
        <!-- You can adjust this table or use a simpler layout -->
        <table class="w-full text-sm text-gray-700 border-collapse">
          <thead>
            <tr class="border-b">
              <th class="px-2 py-1 text-left">Risk</th>
              <th class="px-2 py-1 text-left">Returns</th>
              <th class="px-2 py-1 text-left">Drawdown</th>
              <th class="px-2 py-1 text-left">Loss</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="option in riskToleranceTableData" :key="option.type" class="border-b">
              <td class="px-2 py-1">{{ option.type }}</td>
              <td class="px-2 py-1">{{ option.returnRange }}</td>
              <td class="px-2 py-1">{{ option.drawdown }}</td>
              <td class="px-2 py-1">{{ option.maxLoss }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import ChartComponent from "./ChartComponent.vue";
import { detectUserCountry } from "@/utils/location";

export default {
  name: "PortfolioForm",
  components: { ChartComponent },
  inject: ["calculatorData"],
  data() {
    return {
      startDate: "",
      initialInvestment: "",
      selectedCountry: "",
      selectedRiskTolerance: "",
      transactions: [],
      recurringInvestmentAmount: "",
      recurringFrequency: "",
      finalValue: "",
      selectedWorkflow: "none", // Options: "none", "oneTime", "recurring"
      errors: {},
      loading: false,
      result: null,
      chartData: null,
      chartKey: 0,
      // Show/hide modal
      showRiskModal: false
    };
  },
  computed: {
    manualContributionHelper() {
      if (this.selectedCountry === "US") {
        return "(For example, record that you deposited $5,000 into your IRA on a specific date)";
      } else if (this.selectedCountry === "Canada") {
        return "(For example, record that you deposited $5,000 into your TFSA on a specific date)";
      } else {
        return "(For example, record that you deposited $5,000 into your account on a specific date)";
      }
    },
    riskToleranceTableData() {
      const investment = this.getNumericValue(this.initialInvestment) || 0;
      return [
        { type: "Aggressive", returnRange: "8–10%", drawdown: "40%", maxLoss: investment ? `$${(investment * 0.4).toLocaleString()}` : "N/A" },
        { type: "Balanced", returnRange: "6–8%", drawdown: "30%", maxLoss: investment ? `$${(investment * 0.3).toLocaleString()}` : "N/A" },
        { type: "Conservative", returnRange: "4–6%", drawdown: "20%", maxLoss: investment ? `$${(investment * 0.2).toLocaleString()}` : "N/A" },
        { type: "Risk-Free", returnRange: "2–3%", drawdown: "Minimal", maxLoss: "$0" }
      ];
    }
  },
  methods: {
    getNumericValue(val) {
      if (typeof val === "string") {
        return parseFloat(val.replace(/,/g, ""));
      }
      return parseFloat(val);
    },
    formatField(fieldName) {
      let value = this[fieldName];
      if (!value) return;
      let numericValue = Number(value.toString().replace(/,/g, ""));
      if (!isNaN(numericValue)) {
        this[fieldName] = numericValue.toLocaleString();
      }
    },
    unformatField(fieldName) {
      let value = this[fieldName];
      if (typeof value === "string") {
        this[fieldName] = value.replace(/,/g, "");
      }
    },
    formatTransactionAmount(index) {
      let value = this.transactions[index].amount;
      if (!value) return;
      let numericValue = Number(value.toString().replace(/,/g, ""));
      if (!isNaN(numericValue)) {
        this.transactions[index].amount = numericValue.toLocaleString();
      }
    },
    unformatTransactionAmount(index) {
      let value = this.transactions[index].amount;
      if (typeof value === "string") {
        this.transactions[index].amount = value.replace(/,/g, "");
      }
    },
    addTransaction() {
      this.transactions.push({ date: "", amount: "" });
    },
    removeTransaction(index) {
      this.transactions.splice(index, 1);
    },
    validateForm() {
      this.errors = {};
      if (!this.startDate) {
        this.errors.startDate = "Start Date is required.";
      }
      if (!this.initialInvestment) {
        this.errors.initialInvestment = "Initial Investment is required.";
      }
      if (!this.selectedCountry) {
        this.errors.selectedCountry = "Country selection is required.";
      }
      if (!this.selectedRiskTolerance) {
        this.errors.selectedRiskTolerance = "Risk Tolerance selection is required.";
      }
      if (!this.finalValue) {
        this.errors.finalValue = "Final Portfolio Value is required.";
      }
      if (this.selectedWorkflow === "recurring") {
        if (!this.recurringInvestmentAmount) {
          this.errors.recurringInvestmentAmount = "Recurring Investment Amount is required.";
        }
        if (!this.recurringFrequency) {
          this.errors.recurringFrequency = "Please select a recurring frequency.";
        }
      }
      return Object.keys(this.errors).length === 0;
    },
    async submitData() {
      if (!this.validateForm()) return;
      this.loading = true;
      try {
        // Update global calculatorData with the latest form inputs.
        this.calculatorData.startDate = this.startDate;
        this.calculatorData.initialInvestment = this.initialInvestment;
        this.calculatorData.selectedCountry = this.selectedCountry;
        this.calculatorData.selectedRiskTolerance = this.selectedRiskTolerance;

        const payload = {
          start_date: this.startDate,
          initial_investment: this.getNumericValue(this.initialInvestment),
          transactions:
            this.selectedWorkflow === "oneTime"
              ? this.transactions.map((tx) => ({
                  date: tx.date,
                  amount: this.getNumericValue(tx.amount)
                }))
              : [],
          final_value: this.getNumericValue(this.finalValue),
          country: this.selectedCountry,
          risk_tolerance: this.selectedRiskTolerance,
          recurring_investment_amount:
            this.selectedWorkflow === "recurring" ? this.getNumericValue(this.recurringInvestmentAmount) : null,
          recurring_frequency: this.selectedWorkflow === "recurring" ? this.recurringFrequency : null
        };

        // Use environment variable for the API URL, defaulting to localhost if not set.
        const apiUrl = process.env.VUE_APP_API_URL || "http://localhost:5000";
        const response = await axios.post(`${apiUrl}/calculate_performance`, payload);
        this.result = response.data;

        if (response.data.daily_data) {
          let userBalance = this.getNumericValue(this.initialInvestment);
          const finalVal = this.getNumericValue(this.finalValue);
          const totalDays = response.data.daily_data.length;
          const dailyIncrease = (finalVal - userBalance) / (totalDays || 1);
          const userPortfolioData = [];
          response.data.daily_data.forEach((day) => {
            const tx = this.transactions.find((tx) => tx.date === day.date);
            if (tx) {
              userBalance += this.getNumericValue(tx.amount);
            }
            userBalance += dailyIncrease;
            userPortfolioData.push({
              date: day.date,
              value: userBalance
            });
          });
          const benchmarkData = response.data.daily_data.map((day) => ({
            date: day.date,
            value: day.benchmark_value
          }));
          this.chartData = {
            user: userPortfolioData,
            benchmark: benchmarkData
          };
        }
        this.chartKey++;
      } catch (error) {
        console.error("🚨 Error:", error);
      } finally {
        this.loading = false;
      }
    },
    scrollToCalculator() {
      // Scroll smoothly to the calculator section
      const calculatorSection = document.getElementById("calculator");
      if (calculatorSection) {
        calculatorSection.scrollIntoView({ behavior: "smooth" });
      }
      // Focus the start date input after scrolling (delay for animation)
      setTimeout(() => {
        if (this.$refs.startDateInput) {
          this.$refs.startDateInput.focus();
        }
      }, 500);
    }
  },
  async mounted() {
    if (!this.selectedCountry) {
      const userCountry = await detectUserCountry();
      if (userCountry) {
        this.selectedCountry = userCountry;
      }
    }
  }
};
</script>

<style scoped>
/* Fades in/out the modal overlay and modal content */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
