import { createRouter, createWebHistory } from 'vue-router';
import PortfolioForm from '../components/PortfolioForm.vue';
import BenchmarkReports from '../views/BenchmarkReports.vue';

const routes = [
  {
    path: '/',
    name: 'Calculator',
    component: PortfolioForm,
  },
  {
    path: '/benchmarks',
    name: 'Benchmarks',
    component: BenchmarkReports,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
