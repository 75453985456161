// utils/location.js
export async function detectUserCountry() {
  try {
    const res = await fetch("https://ipapi.co/json/");
    const data = await res.json();
    if (data.country_name === "Canada") return "Canada";
    return "US"; // fallback default
  } catch (e) {
    console.warn("Could not detect location, defaulting to US");
    return "US";
  }
}
