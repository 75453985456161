<template>
  <!-- Banner Section -->
  <section class="relative bg-cover bg-center" style="background-image: url('your-background-image.jpg');">
    <div class="absolute inset-0 bg-gradient-to-b from-white/80 to-white/50"></div>
    <div class="relative max-w-4xl mx-auto py-20 px-6 text-center">
      <h2 class="text-4xl md:text-5xl font-bold text-gray-800 drop-shadow-md">
        Invest with Confidence Using Industry Standard Benchmarks
      </h2>
      <p class="mt-4 text-xl text-gray-600 drop-shadow-sm">
        Discover the trusted benchmarks that define diversified, low-cost ETF portfolios. Compare performance across Aggressive, Balanced, Conservative, and Risk-Free strategies.
      </p>
      <div class="mt-8">
        <button 
          @click="scrollToBenchmarkTable"
          class="inline-block bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-8 rounded transition duration-300 shadow-lg"
        >
          Explore Benchmarks
        </button>
      </div>
    </div>
  </section>

  <!-- Main card container -->
  <div class="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl mt-8 mx-auto space-y-6 min-h-screen">
    <!-- Filter Section -->
    <div class="flex flex-col md:flex-row gap-4">
      <div class="w-full">
        <label class="block text-gray-700 font-semibold mb-1">Country</label>
        <select
          v-model="selectedCountry"
          class="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          <option disabled value="">Select a country</option>
          <option value="US">US</option>
          <option value="Canada">Canada</option>
        </select>
      </div>
    </div>

    <!-- Title and Instruction -->
    <h1 class="text-2xl font-bold text-gray-800 text-center">Benchmarks</h1>
    <p class="text-center text-gray-600 mb-2">Click on a row to view details.</p>

    <!-- Benchmarks Summary Table -->
    <div class="w-full overflow-x-auto" ref="benchmarkTable">
      <table class="w-full bg-white rounded-lg shadow border-collapse text-sm">
        <thead class="border-b bg-gray-100">
          <tr>
            <th class="py-2 px-4 text-left">Bmk</th>
            <th class="py-2 px-4 text-right">YTD</th>
            <th class="py-2 px-4 text-right">Ann</th>
            <th class="py-2 px-4 text-right">DD</th>
            <!-- Extra column for arrow icon (visible on desktop) -->
            <th class="py-2 px-2 text-center hidden md:table-cell"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(benchmark, idx) in filteredBenchmarks"
            :key="idx"
            class="border-b hover:bg-gray-50 transition-colors cursor-pointer focus:bg-gray-100"
            role="button"
            tabindex="0"
            @click="selectBenchmark(benchmark)"
            @keydown.enter="selectBenchmark(benchmark)"
          >
            <td class="py-2 px-4">
              {{ benchmark.title.split(" ").slice(1).join(" ") }}
            </td>
            <td class="py-2 px-4 text-right" :class="returnClass(benchmark.returns['YTD'])">
              {{ benchmark.returns['YTD'] }}
            </td>
            <td class="py-2 px-4 text-right" :class="returnClass(benchmark.returns['Since Inception'])">
              {{ benchmark.returns['Since Inception'] }}
            </td>
            <td class="py-2 px-4 text-right text-red-600">
              {{ benchmark.maxDrawdown ? '-' + benchmark.maxDrawdown + '%' : 'N/A' }}
            </td>
            <!-- Arrow Icon for Desktop -->
            <td class="py-2 px-2 text-center hidden md:table-cell">
              <svg class="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
              </svg>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Detailed View of Selected Benchmark -->
    <div 
      v-if="selectedBenchmark" 
      ref="benchmarkDetails"
      class="bg-gray-50 rounded-lg p-4 shadow space-y-4 animate-fadeIn"
    >
      <h2 class="text-xl font-semibold text-gray-800">
        {{ selectedBenchmark.title.split(" ").slice(1).join(" ") }} Portfolio – Performance & Breakdown
      </h2>
      <p class="text-gray-600">
        This benchmark includes the following securities, historical returns, and descriptions.
      </p>

      <!-- Portfolio Holdings with Short Labels -->
      <div v-if="selectedBenchmark.composition_details && selectedBenchmark.composition_details.length" class="overflow-x-auto">
        <h3 class="font-semibold mt-4 mb-2 text-gray-800 text-center">Portfolio Holdings</h3>
        <table class="w-full bg-white rounded-lg shadow border-collapse text-sm">
          <thead class="border-b bg-gray-100">
            <tr>
              <th class="py-2 px-4 text-left">Security</th>
              <th class="py-2 px-4 text-right">Weight</th>
              <th class="py-2 px-4 text-left">Label</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(item, idx) in selectedBenchmark.composition_details" 
              :key="idx" 
              class="border-b hover:bg-gray-50 transition-colors"
            >
              <td class="py-2 px-4">{{ item.symbol }}</td>
              <td class="py-2 px-4 text-right">{{ (item.weight * 100).toFixed(0) }}%</td>
              <td class="py-2 px-4">
                {{ getShortLabel(item.symbol) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Tabbed Return Section -->
      <div class="mt-4">
        <div class="flex border-b mb-4">
          <button
            class="py-2 px-4 focus:outline-none hover:bg-blue-100"
            :class="{'border-b-2 border-blue-600 text-blue-600': activeReturnTab === 'annualized'}"
            @click="activeReturnTab = 'annualized'"
          >
            Annual Returns
          </button>
          <button
            class="py-2 px-4 focus:outline-none hover:bg-blue-100"
            :class="{'border-b-2 border-blue-600 text-blue-600': activeReturnTab === 'calendar'}"
            @click="activeReturnTab = 'calendar'"
          >
            Calendar Year
          </button>
        </div>

        <!-- Annual Returns Table -->
        <div v-if="activeReturnTab === 'annualized'" class="overflow-x-auto mt-4">
          <table class="w-full bg-white rounded-lg shadow border-collapse text-sm">
            <thead class="border-b bg-gray-100">
              <tr>
                <th class="py-2 px-4 text-left">Period</th>
                <th class="py-2 px-4 text-right">Return</th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(val, period) in annualizedReturns" 
                :key="period" 
                class="border-b hover:bg-gray-50"
              >
                <td class="py-2 px-4">
                  {{
                    period === 'YTD'
                      ? 'Year-to-Date'
                      : (period === 'Since Inception'
                          ? 'Since Inception (' + selectedBenchmarkInceptionYear + ')'
                          : period)
                  }}
                </td>
                <td class="py-2 px-4 text-right" :class="returnClass(val)">{{ val }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Calendar Year Returns Table -->
        <div v-if="activeReturnTab === 'calendar'" class="overflow-x-auto mt-4">
          <template v-if="Object.keys(calendarReturns).length">
            <table class="w-full bg-white rounded-lg shadow border-collapse text-sm">
              <thead class="border-b bg-gray-100">
                <tr>
                  <th class="py-2 px-4 text-left">Year</th>
                  <th class="py-2 px-4 text-right">Return</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(val, year) in calendarReturns" 
                  :key="year" 
                  class="border-b hover:bg-gray-50"
                >
                  <td class="py-2 px-4">{{ year }}</td>
                  <td class="py-2 px-4 text-right" :class="returnClass(val)">{{ val }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p class="py-2 px-2 text-gray-600">No Calendar Year data available.</p>
          </template>
        </div>
      </div>

      <!-- Biggest Drop Section -->
      <div class="overflow-x-auto mt-4">
        <table class="w-full bg-white rounded-lg shadow border-collapse text-sm">
          <tbody>
            <tr class="border-b">
              <td class="py-2 px-4 font-semibold">
                <span title="Also called max drawdown">Biggest Drop from Peak</span>
              </td>
              <td class="py-2 px-4 text-right text-red-600">
                {{ selectedBenchmark.maxDrawdown !== "N/A" ? '-' + selectedBenchmark.maxDrawdown + '%' : 'N/A' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Chart Section -->
      <div class="p-3" style="min-height: 400px;">
        <h3 class="font-semibold mb-1 text-center">
          How This Portfolio Has Grown Over Time
        </h3>
        <p class="text-center text-gray-600 mb-4">Cumulative return from inception</p>
        <ChartComponent 
          v-if="chartData" 
          :chartMode="'benchmark'" 
          :benchmarkData="chartData.benchmark" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ChartComponent from "../components/ChartComponent.vue";
import { detectUserCountry } from "@/utils/location";

export default {
  name: "BenchmarkReports",
  components: { ChartComponent },
  data() {
    return {
      benchmarks: [],
      selectedBenchmark: null,
      chartData: null,
      selectedCountry: "",
      activeReturnTab: "annualized",
      selectedBenchmarkInceptionYear: ""
    };
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL || "http://localhost:5000";
    },
    filteredBenchmarks() {
      if (!this.selectedCountry) return this.benchmarks;
      return this.benchmarks.filter(b =>
        b.title.toLowerCase().includes(this.selectedCountry.toLowerCase())
      );
    },
    annualizedReturns() {
      if (!this.selectedBenchmark || !this.selectedBenchmark.returns) return {};
      const order = ["YTD", "1-Year", "3-Year (Ann.)", "5-Year (Ann.)", "Since Inception"];
      const ret = {};
      order.forEach(key => {
        if (this.selectedBenchmark.returns[key] !== undefined) {
          ret[key] = this.selectedBenchmark.returns[key];
        }
      });
      return ret;
    },
    calendarReturns() {
      if (!this.selectedBenchmark || !this.selectedBenchmark.calendarReturns) return {};
      return this.selectedBenchmark.calendarReturns;
    }
  },
  async mounted() {
    const userCountry = await detectUserCountry();
    if (userCountry) {
      this.selectedCountry = userCountry;
    }
    axios.get(`${this.apiUrl}/benchmark_summary`)
      .then(response => {
        this.benchmarks = response.data;
        this.benchmarks.forEach(benchmark => {
          this.computeDrawdownForBenchmark(benchmark);
        });
      })
      .catch(error => {
        console.error("Error fetching benchmark summary:", error);
      });
  },
  methods: {
    scrollToBenchmarkTable() {
      const el = this.$refs.benchmarkTable;
      if (el && typeof el.scrollIntoView === 'function') {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    selectBenchmark(benchmark) {
      this.selectedBenchmark = benchmark;
      this.fetchTimeseries(benchmark);
      this.$nextTick(() => {
        const detailsEl = this.$refs.benchmarkDetails;
        if (detailsEl && typeof detailsEl.scrollIntoView === 'function') {
          detailsEl.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    },
    fetchTimeseries(benchmark) {
      const parts = benchmark.title.split(" ");
      const country = parts[0];
      const risk = parts.slice(1).join(" ");
      axios.get(`${this.apiUrl}/benchmark_timeseries?country=${country}&risk=${risk}`)
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.selectedBenchmarkInceptionYear = new Date(response.data[0].Date).getFullYear();
          }
          const filteredData = response.data;
          if (filteredData.length > 0) {
            const base = filteredData[0].Cumulative;
            this.chartData = {
              benchmark: filteredData.map(point => ({
                date: point.Date,
                value: ((point.Cumulative / base) - 1) * 100,
              }))
            };
          } else {
            this.chartData = null;
          }
        })
        .catch(error => {
          console.error("Error fetching benchmark timeseries:", error);
        });
    },
    computeDrawdownForBenchmark(benchmark) {
      const parts = benchmark.title.split(" ");
      const country = parts[0];
      const risk = parts.slice(1).join(" ");
      axios.get(`${this.apiUrl}/benchmark_timeseries?country=${country}&risk=${risk}`)
        .then(response => {
          const data = response.data;
          if (data.length > 0) {
            const dd = this.calculateMaxDrawdown(data);
            benchmark.maxDrawdown = dd;
          } else {
            benchmark.maxDrawdown = "N/A";
          }
        })
        .catch(error => {
          console.error("Error fetching timeseries for", benchmark.title, error);
          benchmark.maxDrawdown = "N/A";
        });
    },
    calculateMaxDrawdown(data) {
      let maxSoFar = -Infinity;
      let maxDrawdown = 0;
      data.forEach(point => {
        const cumValue = point.Cumulative;
        if (cumValue > maxSoFar) {
          maxSoFar = cumValue;
        }
        const dd = (cumValue - maxSoFar) / maxSoFar;
        if (dd < maxDrawdown) {
          maxDrawdown = dd;
        }
      });
      return Math.abs(maxDrawdown * 100).toFixed(1);
    },
    returnClass(val) {
      if (!val || val === "N/A") return "";
      const num = parseFloat(val.replace('%', ''));
      return num >= 0 ? "text-green-600" : "text-red-600";
    },
    getShortLabel(symbol) {
      const shortLabels = {
        'VTI': 'US Equity',
        'VXUS': 'Int’l Equity',
        'BND': 'US Bonds',
        'BIL': 'Risk Free',
        'XAW.TO': 'Int’l Equity',
        'XIC.TO': 'Canadian Equity',
        'XBB.TO': 'Canadian Bond',
        'PSA.TO': 'Risk Free'
      };
      return shortLabels[symbol] || 'Unknown Symbol';
    }
  }
};
</script>

<style scoped>
html {
  overflow-y: scroll;
}

/* Simple fade-in animation for the details section */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}
.animate-fadeIn {
  animation: fadeIn 0.5s ease-out;
}

/* Responsive adjustments for mobile screens */
@media (max-width: 640px) {
  table th, table td {
    padding: 0.5rem 0.5rem;
    font-size: 0.875rem;
  }
}
</style>
