<template>
  <div id="app" class="min-h-screen bg-gray-100 font-sans">
    <!-- Responsive Header -->
    <header class="bg-white shadow">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center h-16">
          <!-- Left: Logo & Desktop Navigation -->
          <div class="flex items-center">
            <router-link to="/" class="flex items-center">
              <svg class="h-8 w-8 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 15l6-6 4 4 8-8"
                />
              </svg>
              <span class="ml-2 text-xl font-bold text-gray-800">SinceInception</span>
            </router-link>
            <!-- Desktop Navigation -->
            <nav class="hidden sm:ml-6 sm:flex sm:space-x-8">
              <!-- Calculator Tab -->
              <router-link to="/" v-slot="{ href, isActive }">
                <a
                  :href="href"
                  :class="[
                    'inline-flex items-center px-1 pt-1 text-sm font-medium',
                    isActive 
                      ? 'border-b-2 border-indigo-500 text-gray-900'
                      : 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  ]"
                >
                  Calculator
                </a>
              </router-link>
              <!-- Benchmarks Tab -->
              <router-link to="/benchmarks" v-slot="{ href, isActive }">
                <a
                  :href="href"
                  :class="[
                    'inline-flex items-center px-1 pt-1 text-sm font-medium',
                    isActive 
                      ? 'border-b-2 border-indigo-500 text-gray-900'
                      : 'border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                  ]"
                >
                  Lazy Portfolio Benchmarks
                </a>
              </router-link>
            </nav>
          </div>
          <!-- Mobile Menu Toggle Button -->
          <div class="sm:hidden">
            <button
              @click="toggleMobileMenu"
              type="button"
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open main menu</span>
              <svg v-if="!mobileMenuOpen" class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg v-else class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <!-- Mobile Navigation Menu -->
      <div v-if="mobileMenuOpen" class="sm:hidden">
        <nav class="pt-2 pb-4 space-y-1">
          <router-link to="/" v-slot="{ href, isActive }">
            <a
              :href="href"
              @click="closeMobileMenu"
              :class="[
                'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                isActive 
                  ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                  : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
              ]"
            >
              Calculator
            </a>
          </router-link>
          <router-link to="/benchmarks" v-slot="{ href, isActive }">
            <a
              :href="href"
              @click="closeMobileMenu"
              :class="[
                'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                isActive 
                  ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                  : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800'
              ]"
            >
              Lazy Portfolio Benchmarks
            </a>
          </router-link>
        </nav>
      </div>
    </header>

    <!-- Main Content -->
    <main class="container mx-auto p-6">
      <router-view />
    </main>

    <!-- Footer -->
    <footer class="bg-white border-t py-4 text-center text-gray-500 text-sm">
      <div class="max-w-7xl mx-auto px-4">
        <p>&copy; 2025 Your Company. All rights reserved.</p>
        <div class="mt-2 flex justify-center space-x-4">
          <a href="#" class="text-indigo-500 hover:text-indigo-700">Terms</a>
          <a href="#" class="text-indigo-500 hover:text-indigo-700">Privacy</a>
          <a href="#" class="text-indigo-500 hover:text-indigo-700">Contact</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { reactive, provide, ref } from 'vue';

export default {
  name: "App",
  setup() {
    // Global reactive state for calculator inputs
    const calculatorData = reactive({
      startDate: '',
      initialInvestment: '',
      selectedCountry: '',
      selectedRiskTolerance: '',
      // Add more fields as needed.
    });
    provide('calculatorData', calculatorData);

    const mobileMenuOpen = ref(false);
    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };
    const closeMobileMenu = () => {
      mobileMenuOpen.value = false;
    };

    return { calculatorData, mobileMenuOpen, toggleMobileMenu, closeMobileMenu };
  },
};
</script>

<style>
@import "@/assets/main.css";
</style>
