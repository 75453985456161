import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import './assets/main.css';
import router from './router';



// Set API Base URL to your Flask backend
axios.defaults.baseURL = "http://your-ec2-ip:5000";

createApp(App)
  .use(router)
  .mount('#app');
