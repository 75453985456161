<template>
  <!-- Chart Preview Section -->
  <div class="bg-white shadow-lg rounded-lg p-6 mt-6 w-full max-w-3xl mx-auto">
    <h3 class="text-xl font-semibold text-gray-700 text-center mb-4">
      Investment Growth Comparison
    </h3>

    <!-- Container with a flexible height -->
    <div class="relative w-full" style="min-height: 350px;">
      <canvas ref="chartCanvas" class="w-full h-full"></canvas>
    </div>

    <!-- Button to expand chart: visible only on mobile (hidden on >= sm) -->
    <div class="mt-4 text-center block sm:hidden">
      <button @click="openChartModal" class="bg-blue-600 text-white px-4 py-2 rounded">
        Expand Chart
      </button>
    </div>
  </div>

  <!-- Chart Modal -->
  <transition name="fade">
    <div
      v-if="showChartModal"
      class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      @click.self="closeChartModal"
    >
      <div class="bg-white rounded-lg p-4 max-w-lg w-full relative">
        <!-- Close button -->
        <button
          class="absolute top-2 right-2 text-gray-500 hover:text-gray-700 text-2xl leading-none"
          @click="closeChartModal"
        >
          &times;
        </button>
        <h3 class="text-xl font-semibold text-gray-700 mb-4 text-center">
          Investment Growth Comparison
        </h3>
        <div class="relative w-full" style="min-height: 400px;">
          <canvas ref="modalChartCanvas" class="w-full h-full"></canvas>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { Chart, registerables } from "chart.js";
import "chartjs-adapter-date-fns"; 

// Register Chart.js components only once.
if (!Chart._registered) {
  Chart.register(...registerables);
  Chart._registered = true;
}

export default {
  name: "ChartComponent",
  props: {
    chartMode: { type: String, default: "portfolio" },
    benchmarkData: { type: Array, required: true },
    userData: { type: Array, default: () => [] },
  },
  data() {
    return {
      chartInstance: null,
      modalChartInstance: null,
      showChartModal: false,
      renderFrame: null,
    };
  },
  mounted() {
    this.scheduleRenderChart();
  },
  watch: {
    benchmarkData: {
      handler() {
        this.scheduleRenderChart();
        if (this.showChartModal) {
          this.scheduleRenderModalChart();
        }
      },
      deep: true,
    },
    userData: {
      handler() {
        this.scheduleRenderChart();
        if (this.showChartModal) {
          this.scheduleRenderModalChart();
        }
      },
      deep: true,
    },
    chartMode() {
      this.scheduleRenderChart();
      if (this.showChartModal) {
        this.scheduleRenderModalChart();
      }
    },
  },
  beforeUnmount() {
    if (this.renderFrame) {
      cancelAnimationFrame(this.renderFrame);
      this.renderFrame = null;
    }
    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartInstance = null;
    }
    if (this.modalChartInstance) {
      this.modalChartInstance.destroy();
      this.modalChartInstance = null;
    }
  },
  methods: {
    scheduleRenderChart() {
      if (this.renderFrame) cancelAnimationFrame(this.renderFrame);
      this.renderFrame = requestAnimationFrame(() => {
        this.renderChart();
      });
    },
    scheduleRenderModalChart() {
      requestAnimationFrame(() => {
        this.renderModalChart();
      });
    },

    getAggregationFrequency(count) {
      if (count <= 30) return "daily";
      else if (count <= 60) return "weekly";
      else return "monthly";
    },
    determineTimeUnit(data) {
      if (!data || !data.length) return "month";
      const firstDate = new Date(data[0].x);
      const lastDate = new Date(data[data.length - 1].x);
      const diffDays = (lastDate - firstDate) / (1000 * 60 * 60 * 24);
      if (diffDays < 31) return "day";
      else if (diffDays < 61) return "week";
      else return "month";
    },
    aggregateDataByFrequency(data, frequency) {
      const sorted = [...data].sort((a, b) => new Date(a.date) - new Date(b.date));
      if (frequency === "daily") {
        return sorted.map(d => ({ x: d.date, y: d.value }));
      }
      const groups = {};
      sorted.forEach(item => {
        const dateObj = new Date(item.date);
        let groupKey;
        if (frequency === "weekly") {
          const year = dateObj.getFullYear();
          const week = this.getWeekNumber(dateObj);
          groupKey = `${year}-W${week}`;
        } else {
          const year = dateObj.getFullYear();
          const month = dateObj.getMonth() + 1;
          groupKey = `${year}-${month < 10 ? "0" + month : month}`;
        }
        if (!groups[groupKey]) groups[groupKey] = [];
        groups[groupKey].push(item);
      });
      const aggregated = [];
      for (const key in groups) {
        const groupItems = groups[key];
        groupItems.sort((a, b) => new Date(a.date) - new Date(b.date));
        const lastItem = groupItems[groupItems.length - 1];
        aggregated.push({ x: lastItem.date, y: lastItem.value });
      }
      aggregated.sort((a, b) => new Date(a.x) - new Date(b.x));
      return aggregated;
    },
    getWeekNumber(date) {
      const d = new Date(date);
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 3 - ((d.getDay() + 6) % 7));
      const week1 = new Date(d.getFullYear(), 0, 4);
      return 1 + Math.round(((d - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7);
    },
    getMinValue(benchmark, user) {
      let allValues = [];
      if (this.chartMode === "portfolio") {
        allValues.push(...benchmark.map(d => d.y));
        allValues.push(...(user || []).map(d => d.y));
      } else {
        allValues.push(...benchmark.map(d => d.y));
      }
      return allValues.length ? Math.min(...allValues) : 0;
    },
    getDatasets(benchmarkData, userData) {
      const benchmarkLine = {
        label: this.chartMode === "portfolio" ? "Benchmark ($)" : "Benchmark (%)",
        data: benchmarkData,
        borderColor: "#FF5733",
        backgroundColor: "rgba(255, 87, 51, 0.2)",
        fill: true,
        tension: 0.3,
        pointRadius: 3,
        pointHoverRadius: 6,
      };
      if (this.chartMode === "portfolio" && userData?.length) {
        const userLine = {
          label: "User ($)",
          data: userData,
          borderColor: "#4CAF50",
          backgroundColor: "rgba(76, 175, 80, 0.2)",
          fill: true,
          tension: 0.3,
          pointRadius: 3,
          pointHoverRadius: 6,
        };
        return [userLine, benchmarkLine];
      }
      return [benchmarkLine];
    },
    renderChart() {
      const canvas = this.$refs.chartCanvas;
      if (!canvas || !this.benchmarkData?.length) return;
      if (this.chartInstance) {
        this.chartInstance.destroy();
        this.chartInstance = null;
      }
      const rawBenchmark = [...this.benchmarkData];
      const rawUser = [...(this.userData || [])];
      const maxDataPoints = Math.max(rawBenchmark.length, rawUser.length);
      const frequency = this.getAggregationFrequency(maxDataPoints);
      const aggregatedBenchmark = this.aggregateDataByFrequency(rawBenchmark, frequency);
      const aggregatedUser = this.aggregateDataByFrequency(rawUser, frequency);
      const timeUnit = this.determineTimeUnit(aggregatedBenchmark);

      this.chartInstance = new Chart(canvas, {
        type: "line",
        data: {
          datasets: this.getDatasets(aggregatedBenchmark, aggregatedUser),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              position: "bottom",
              labels: { font: { size: 13 } }
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                parser: "yyyy-MM-dd",
                unit: timeUnit,
                displayFormats: {
                  day: "MMM d",
                  week: "MMM d",
                  month: "MMM yy"
                }
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: timeUnit === "day" ? 7 : 5,
                color: "#444",
                maxRotation: 45,
                minRotation: 0
              },
              title: {
                display: true,
                text: "Date",
                font: { size: 14, weight: "bold" },
                color: "#666"
              }
            },
            y: {
              ticks: { color: "#444" },
              title: {
                display: true,
                text: this.chartMode === "portfolio" 
                  ? "Portfolio Value ($)" 
                  : "Benchmark Return (%)",
                font: { size: 14, weight: "bold" },
                color: "#666"
              },
              beginAtZero: this.chartMode === "benchmark",
              min: this.chartMode === "portfolio"
                ? this.getMinValue(aggregatedBenchmark, aggregatedUser)
                : undefined,
            },
          }
        },
      });
    },
    renderModalChart() {
      const canvas = this.$refs.modalChartCanvas;
      if (!canvas || !this.benchmarkData?.length) return;
      if (this.modalChartInstance) {
        this.modalChartInstance.destroy();
        this.modalChartInstance = null;
      }
      const rawBenchmark = [...this.benchmarkData];
      const rawUser = [...(this.userData || [])];
      const maxDataPoints = Math.max(rawBenchmark.length, rawUser.length);
      const frequency = this.getAggregationFrequency(maxDataPoints);
      const aggregatedBenchmark = this.aggregateDataByFrequency(rawBenchmark, frequency);
      const aggregatedUser = this.aggregateDataByFrequency(rawUser, frequency);
      const timeUnit = this.determineTimeUnit(aggregatedBenchmark);

      this.modalChartInstance = new Chart(canvas, {
        type: "line",
        data: {
          datasets: this.getDatasets(aggregatedBenchmark, aggregatedUser),
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              position: "bottom",
              labels: { font: { size: 13 } }
            }
          },
          scales: {
            x: {
              type: "time",
              time: {
                parser: "yyyy-MM-dd",
                unit: timeUnit,
                displayFormats: {
                  day: "MMM d",
                  week: "MMM d",
                  month: "MMM yy"
                }
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: timeUnit === "day" ? 7 : 5,
                color: "#444",
                maxRotation: 45,
                minRotation: 0
              },
              title: {
                display: true,
                text: "Date",
                font: { size: 14, weight: "bold" },
                color: "#666"
              }
            },
            y: {
              ticks: { color: "#444" },
              title: {
                display: true,
                text: this.chartMode === "portfolio" 
                  ? "Portfolio Value ($)" 
                  : "Benchmark Return (%)",
                font: { size: 14, weight: "bold" },
                color: "#666"
              },
              beginAtZero: this.chartMode === "benchmark",
              min: this.chartMode === "portfolio"
                ? this.getMinValue(aggregatedBenchmark, aggregatedUser)
                : undefined,
            },
          }
        },
      });
    },
    openChartModal() {
      this.showChartModal = true;
      this.$nextTick(() => {
        this.scheduleRenderModalChart();
      });
    },
    closeChartModal() {
      this.showChartModal = false;
      if (this.modalChartInstance) {
        this.modalChartInstance.destroy();
        this.modalChartInstance = null;
      }
    },
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
